quer
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
//import Multiselect from "vue-multiselect";
//import CKEditor from "@ckeditor/ckeditor5-vue";
//import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { apiRequest } from "@/helpers/api-call";
//import { format } from "date-fns";
import router from "@/router/index.js";
import Vue from "vue";

/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    //Multiselect,
    //ckeditor: CKEditor.component,
  },
  data() {
    return {

      fields: [
        { key: "moyenTransport", label: "Option" },
        { key: "prixBase", sortable: true, label: "Prix 1er km" },
        { key: "prixSecond", sortable: true, label: "Prix des km++ " },
        { key: "prixIncitation", sortable: true, label: "Incitation" },
        { key: "montantAttenteParMin", sortable: true, label: "Tarif attente", },
        { key: "montantParMinPause_fird", sortable: true, label: "Montant-Pause/(3mn)", },
        { key: "montantParMinPause_last", sortable: true, label: "Montant-Pause/(3mn++)", },
        { key: "action", sortable: true, label: "Action", }




      ],

      fieldsP: [
        { key: "debut", label: "Heure de début" },
        { key: "fin", sortable: true, label: "Heure de Fin" },
        { key: "pourcentage", sortable: true, label: "Pourcentage" },
        { key: "weekend", sortable: true, label: "Période", },
        { key: "action", sortable: true, label: "Action", }


      ],
      covFields: [
        { key: "moyensTransport", sortable: true, label: "Option" },
        { key: "distances", sortable: true, label: "Distance" },
        { key: "plage", sortable: true, label: "Plage" },
        { key: "prix", sortable: true, label: "Prix" },
        { key: "action", sortable: true, label: "Action" }

      ],
      title: "GESTION DES TARIFS ET COMMISIONS",
      items: [
        {
          text: "Dashboard",
        },
        {
          text: "TARIFS ET COMMISIONS",
          active: true,
        },
      ],
      totalRows: 1,
      deleteId: 0,
      deleteModal: false,
      changeCurrent: 1,
      changePerPage: 100,
      currentPage: 1,
      currentPageD: 1,
      perPage: 100,
      perPageD: 100,
      pageOptions: [100, 250, 500],
      pageOptionsD: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      paginatedDate: [],
      carData: [],
      goClassData:[],
      VTCM:[],
      pourcentageData: [],
      covData: [],
      tarifFormData: {
        id: null,
        moyenTransport: "",
        prixBase: null,
        prixSecond: null,
        prixIncitation: null,
        montantAttenteParMin: null,
        montantParMinPause_fird: null,
        montantParMinPause_last: null,
        reduction: null,
        reduction_active: false,
        night_pourcentage: [],
        weekend_pourcentage: null,
        service:'',
      },


      horrairesTarifs: [{ start: 0, end: 0, percentage: 0 }],
      editTarifModal: false,
      editP: false,
      addPModal: false,
      editCovTarifModal: false,
      interval: {
        id: 0,
        debut: null,
        fin: null,
        weekend: false,
        pourcentage: 0
      }


    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.dataUser.length;
    },
    rowsinactif() {
      return this.dataUserD.length;
    },
  },
  async mounted() {

    this.getTarifs();
    this.getCovTarifs();
    this.getPercentage();

  },
  methods: {


    async getTarifs() {
      //  messageContent
      const response = await apiRequest('GET', 'taxi-tarif', false)
      if (response && response.status == 200) {
        console.log("getTarifs taxi-tarif", response.data)
        const sortedData = response.data.sort((a, b) => b.id - a.id);

        this.carData = sortedData.filter(el => el.service == 'VTC')
        console.log("this car ", this.carData);
        this.goClassData = sortedData.filter(el => el.service == 'GOCLASS')
        console.log("this car ", this.carData);
    
      this.VTCM = sortedData.filter(el => el.service == 'VTCM')
      }
     
       
     

    },
    handlePEdit(item) {
      if (item.id) {

        this.editP = true;
        this.interval.id = item.id;
        this.interval.debut = item.debut;
        this.interval.fin = item.fin;
        this.interval.weekend = item.weekend;
        this.interval.pourcentage = item.pourcentage;

      } else {
        this.interval.id = 0
        this.addPModal = true;
      }


    },
    async changeP() {

      if (this.interval.id != 0) {

        const response = await apiRequest('PUT', 'update-taxi-tarif-maj',
          {
            id: this.interval.id,
            debut: this.interval.debut,
            fin: this.interval.fin,
            pourcentage: this.interval.pourcentage,
            //reduction_active : this.interval.reduction_active,

          })
        if (response && response.status == 200) {
          console.log(response)
          this.$toast.success('Opération réussie')
          this.getPercentage()
          this.editP = false;

        } else {
          this.$toast.error('Une erreur est survenue')

        }

      } else {

        const response = await apiRequest('POST', 'add-taxi-tarif-maj',
          {

            debut: this.interval.debut,
            fin: this.interval.fin,
            pourcentage: this.interval.pourcentage,
            weekend: this.interval.weekend,

          })
        if (response && response.status == 200) {
          this.$toast.success('Opération réussie')
          this.getPercentage()
          this.addPModal = false;

        } else {
          this.$toast.error('Une erreur est survenue')

        }
      }


    },
    async getPercentage() {
      this.pourcentageData.length = 0
      //  messageContent
      const response = await apiRequest('GET', 'all-taxi-tarif-maj', false)
      if (response && response.status == 200) {
        console.log(response)
        //console.log("D ta up here",response .data)
        const sortedData = response.data.sort((a, b) => b.id - a.id);

        sortedData.map(el => {
          this.pourcentageData.push(el);

        })
        // const result = Object.groupBy(this.pourcentageData, ({ weekend }) => weekend);
        // console.log("resultat",result)
        // this.pourcentageData =[]
        // this.pourcentageData.push(result.false)
        //this.pourcentageData.push(result.true)
        console.log("this pourcentageData push", this.pourcentageData);
      }

    },

    async getCovTarifs() {
      //  messageContent
      const response = await apiRequest('GET', 'tarif-rmo', false)
      if (response && response.status == 200) {
        //console.log("D ta up here",response .data)
        const sortedData = response.data.sort((a, b) => b.id - a.id);

        sortedData.map(el => {
          this.covData.push(el);
        })
        console.log("this cov ", this.covData);
      }

    },

    async editCovTarifModalOpener(el) {
      console.log("value", el)

      this.editCovTarifModal = true

      this.tarifFormData = el

      console.log('Form data', this.tarifFormData)

    },
    async editCovTarifMethod() {
      //  messageContent

      console.log("tdata", this.tarifFormData)
      this.editCovTarifModal = false;

      const response = await apiRequest('PUT', 'tarif-rmo/update', {
        id: this.tarifFormData.id,
        prix: this.tarifFormData.prix,

      })
      if (response && response.status == 200) {
        //console.log("D ta up here",response .data)
        this.editCovTarifModal = false

        this.$toast.success("Modifications apportées avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        this.carData = []
        await this.getTarifs();

      } else {
        this.editCovTarifModal = false
        this.$toast.error("Echec de la modification !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });

      }

    },
    async editTarifModalOpener(el) {
      console.log("value", el)

      this.editTarifModal = true

      this.tarifFormData = {
        id: el.id,
        prixBase: el.prixBase,
        prixSecond: el.prixSecond,
        moyenTransport: el.moyenTransport,
        prixIncitation: el.prixIncitation,
        montantAttenteParMin: el.montantAttenteParMin,
        montantParMinPause_fird: el.montantParMinPause_fird,
        montantParMinPause_last: el.montantParMinPause_last,
        reduction: el.reduction,
        reduction_active: el.reduction_active,
        service:el.service,

      };



      console.log('Tarifss data', this.tarifFormData.night_pourcentage)
      this.horrairesTarifs = [];
      this.tarifFormData.night_pourcentage.map(item => this.horrairesTarifs.push({ start: item.start, end: item.end, percentage: item.percentage }))
      console.log('Tarifss horrairesTarifs', this.horrairesTarifs)
      console.log('Form data', this.tarifFormData)


    },
    handleDelete(id) {

      this.deleteId = id;
      this.deleteModal = true

    },
    async deleteP() {
      const response = await apiRequest('DELETE', 'destroy-taxi-tarif-maj', { id: this.deleteId })
      console.log("response", response)
      if (response.status == 200) {
        this.deleteModal = false
        this.getPercentage()
        this.$toast.success("Opération réusssie")

      }

    },
    logdata() {
      console.log("tthis.horrairesTarifs", this.horrairesTarifs)
    },
    async newTarifMethod() {
      //  messageContent
      console.log("this.horrairesTarifs", this.horrairesTarifs)
      console.log("tdata", this.tarifFormData)
      this.editTarifModal = false;

      const response = await apiRequest('POST', 'edit-taxi-tarif', {
        moyenTransport: this.tarifFormData.moyenTransport,
        prixBase: this.tarifFormData.prixBase,
        prixSecond: this.tarifFormData.prixSecond,

        prixIncitation: this.tarifFormData.prixIncitation,
        montantParMinPause_fird: this.tarifFormData.montantParMinPause_fird,
        montantParMinPause_last: this.tarifFormData.montantParMinPause_last,
        montantAttenteParMin: this.tarifFormData.montantAttenteParMin,
        night_pourcentage: JSON.stringify(this.horrairesTarifs),
        weekend_pourcentage: this.tarifFormData.weekend_pourcentage,
        reduction: this.tarifFormData.reduction,
        reduction_active: this.tarifFormData.reduction_active,
        service:  this.tarifFormData.service,


      })
      if (response && response.status == 200) {
        console.log("Retrieve", this.tarifFormData)
        this.editTarifModal = false

        this.$toast.success("Modifications apportées avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        this.carData = []
        await this.getTarifs();

      } else {

        this.$toast.error("Echec de la modification !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });

      }

    },



    deleteUser(row) {
      this.userToUse = row.id;
      // this.showDelete = true;
    },
    setDepartContry: function (place) {
      if (place) {
        this.infoUser.ville = place.formatted_address;
      }
    },

    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.badge === true) return "table-warning";
    },
    activeUserPop(row) {
      this.userToUse = row.id;
      this.deleteModal = true;
    },
    position() {
      Vue.swal({
        position: "center-end",
        icon: "success",
        title: "Mise à jour effectuée",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    goToDetail(row) {
      router.push(`/user/user_info/${row.id}`);
    },
    openNewTab(row) {
      window.open(
        `https://adminrmobility.raynis.co/user/user_info/${row.id}`,
        "_blank"
      );
    },


  },

  watch: {

  },
};

/* const dots = document.querySelector('.loaading__dots')
  const addAnimate = ()=>{
    dots.classList.add('animate')
    setTimeout(()=>{
      dots.classList.remove('aniamte');
      setTimeout(()=>{
        addAnimate()
      },100)
    }, 2600)


    }
    addAnimate(); */
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row rowBase">
      <div class="col-lg-12 ">
        <div class="card p-4 ">




          <b-tabs pills card content-class="mt-3" justified>
            <b-tab active class="" title="VTC">

              <b-table striped responsive :items="carData" :fields="fields">
                <template #cell(action)="row">
                  <div><a @click.prevent="editTarifModalOpener(row.item)"><i class="fas fa-pen md"></i></a></div>
                </template>
              </b-table>
              <div class="my-5 d-flex align-items-center" style="font-size:larger; font-weight: bold;"> Les majorations
                <button class="btn btn-primary mx-3 mb-2" @click="handlePEdit"> Ajouter </button></div>
              <b-table striped responsive :items="pourcentageData" :fields="fieldsP">
                <template #cell(action)="row">
                  <div class="d-flex justify-content-between">
                    <a class="d-block " @click.prevent="handlePEdit(row.item)"><i class="fas fa-pen md"></i></a>
                    <a class=" d-block text-danger" @click.prevent="handleDelete(row.item.id)"><i
                        class="fas fa-trash md"></i></a>
                  </div>
                </template>
                <template #cell(weekend)="row">
                  <div>
                    <span v-if="row.value === true">
                      Dimanche
                    </span>

                    <span v-if="row.value === false">
                      En semaine
                    </span>
                  </div>
                </template>
              </b-table>

            </b-tab>

            <b-tab class="" title="GOCLASS">

              <b-table striped responsive :items="goClassData" :fields="fields">
                <template #cell(action)="row">
                  <div><a @click.prevent="editTarifModalOpener(row.item)"><i class="fas fa-pen md"></i></a></div>
                </template>
              </b-table>

            </b-tab>
            <b-tab class="" title="AÉROPORT">

              <b-table striped responsive :items="VTCM" :fields="fields">
                <template #cell(action)="row">
                  <div><a @click.prevent="editTarifModalOpener(row.item)"><i class="fas fa-pen md"></i></a></div>
                </template>
              </b-table>

            </b-tab>
            <b-tab class="" title="Covoiturage">

              <b-table striped responsive :items="covData" :fields="covFields">
                <template #cell(action)="row">
                  <div><a @click.prevent="editCovTarifModalOpener(row.item)"><i class="fas fa-pen md"></i></a></div>
                </template>
              </b-table>

            </b-tab>

          </b-tabs>










          <!-- <div v-for=" el in carData" :key="el.id" class="col-xl-2 col-lg-3  col-md-3 p-2 box m-3 ">
              <div class="formule d-flex justify-content-between">
                <div> {{ el.moyenTransport }}</div>
                <div><a @click.prevent="editTarifModalOpener(el)"><i class="fas fa-pen md"></i></a></div>
              </div>
              <div class="detail-prix"> Premier Kilomètre: <span class=" box-tarif"> {{ el.prixBase }}</span></div>
              <div class="detail-prix"> Kilometre addition:<span class=" box-tarif"> {{ el.prixSecond }}</span> </div>
              <div class="detail-prix"> Montant Assurance: <span class=" box-tarif"> {{ el.prixIncitation }}</span>
              </div>
              <div class="detail-prix"> Attente/min: <span class=" box-tarif"> {{ el.montantAttenteParMin }}</span>
              </div>
            </div> -->
        </div>
      </div>
    </div>
    <b-modal id="modal-backdrop" centered v-model="deleteModal"
      title="Voulez-vous vraiment supprimer cette majoration ?" title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="deleteModal = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="deleteP()">Oui</b-button>
      </div>
    </b-modal>


    <b-modal id="modal-backdrop" centered title="Voulez-vous vraiment conserver les modifications apportées ?"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4">Oui</b-button>
      </div>
    </b-modal>
    <!-- Change commission-->
    <b-modal v-model="editTarifModal" size="lg" title="Modifier les informations" centered>
      <form>

        <div class="row form-group">
          <div class=" col-md-6 form-group">
            <label> Prix du premier kilomètre</label>
            <input type="text" class="form-control" placeholder="Prix du premier kilomètre"
              v-model="tarifFormData.prixBase" />
          </div>
          <div class="col-md-6 form-group">
            <label> Prix Kilomètre additionnels</label>
            <input type="text" class="form-control" placeholder="Prix Kilomètre additionnels"
              v-model="tarifFormData.prixSecond" />
          </div>
        </div>

        <div class="row form-group">
          <div class="col-md-6 form-group">
            <label> Montant de l'Incitation</label>
            <input type="text" class="form-control" placeholder="Montant de l'Incitation"
              v-model="tarifFormData.prixIncitation" />
          </div>
          <div class="col-md-6 form-group">
            <label> Montant de l'attente/min</label>
            <input type="text" class="form-control" placeholder="Attente/min"
              v-model="tarifFormData.montantAttenteParMin" />
          </div>
        </div>
        <div class="row form-group">
          <div class="col-md-6 form-group">
            <label> Montant attente (3 premières min)</label>
            <input type="text" class="form-control" placeholder="Attente 3 first minutes"
              v-model="tarifFormData.montantParMinPause_fird" />
          </div>
          <div class="col-md-6 form-group">
            <label> Montant attente (minutes additionnelles)</label>
            <input type="text" class="form-control" placeholder="Attente/min"
              v-model="tarifFormData.montantParMinPause_last" />
          </div>
        </div>
        <div class="row form-group">
          <div class="col-md-6 form-group">
            <label>Réduction</label>
            <input type="text" class="form-control" placeholder="Pourcentage de la réduction"
              v-model="tarifFormData.reduction" />
          </div>
          <div class="col-md-6 form-group">
            <label>Réduction active</label>
            <select v-model="tarifFormData.reduction_active" id="m" class="form-control">
              <option :value="true" :key="1">OUI</option>
              <option :value="false" :key="2">NON</option>
            </select>
          </div>
        </div>




      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="editTarifModal = false">Fermer</b-button>
        <b-button variant="primary" @click="newTarifMethod">
          Modifier

        </b-button>
      </template>
    </b-modal>
    <!-- -->

    <!-- edit  pourcentage-->
    <b-modal v-model="editP" size="lg" title="Modifier les pourcentages" centered>
      <form>

        <div class="form-group">
          <div class="mb-3">
            <h5>Intervalle </h5>
            <div class=" form-group  justify-content-between pt-3">
              <label for="start">Heure de début {{ interval.debut }}</label>
              <input id="start" type="time" class="form-control mb-3 " placeholder="item.start"
                v-model="interval.debut" />
              <label for="end">Heure de fin {{ interval.fin }}</label>
              <input type="time" id="end" class="form-control mb-3  " placeholder="item.end" v-model="interval.fin" />
              <label for="percentage">Pourcentage : {{ interval.pourcentage }}</label>
              <input type="text" id="percentage" class="form-control mb-3 " placeholder="item.percentage"
                v-model="interval.pourcentage" />
              <!-- <label for="weekend">Dimanche</label>
              <select v-model="interval.weekend" class="form-control" id="">
                <option :key="1" :value="false">En semaine</option>
                <option  :key="2" :value="true">Le Dimanche</option>
              </select> -->

            </div>



          </div>

        </div>


      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="editP = false">Fermer</b-button>
        <b-button @click='changeP()' variant="primary">
          Modifier

        </b-button>
      </template>
    </b-modal>



    <b-modal v-model="addPModal" size="lg" title="Ajouter une majoration" centered>
      <form>

        <div class="mb-3">
          <h5>Intervalle </h5>
          <div class=" form-group  justify-content-between pt-3">

            <label for="start">Heure de début</label>
            <input id="start" type="time" class="form-control mb-3 " placeholder="item.start"
              v-model="interval.debut" />
            <label for="end">Heure de fin</label>
            <input type="time" id="end" class="form-control mb-3  " placeholder="item.end" v-model="interval.fin" />
            <label for="percentage">Pourcentage</label>
            <input type="text" id="percentage" class="form-control mb-3 " placeholder="item.percentage"
              v-model="interval.pourcentage" />
            <label for="weekend">Dimanche </label>
            <select v-model="interval.weekend" class="form-control" id="">
              <option :key="1" :value="false">En semaine</option>
              <option :key="2" :value="true">Le Dimanche</option>
            </select>

          </div>



        </div>




      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="addPModal = false">Fermer</b-button>
        <b-button variant="primary" @click="changeP()">
          Ajouter

        </b-button>
      </template>
    </b-modal>
    <!-- Change commission-->
    <b-modal v-model="editCovTarifModal" title="Modifier les informations" centered>
      <form>

        <div class="form-group">
          <label> Prix </label>
          <input type="text" class="form-control" placeholder="Prix" v-model="tarifFormData.prix" />
        </div>


      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="editCovTarifModal = false">Fermer</b-button>
        <b-button variant="primary" @click="editCovTarifMethod()">
          Modifier

        </b-button>
      </template>
    </b-modal>
    <!-- -->
  </Layout>
</template>

<style>
.box {
  background-color: #3AAA35;
  color: white;
  border-radius: 5px;
  font-size: 1.5rem;
  padding: 20px;
  box-shadow: 5px 10px rgba(144, 238, 144, 0.417);
}

.box-tarif {
  font-weight: light;
  font-size: 1em;
}

.formule {
  font-weight: bold;
}

.detail-prix {
  font-size: 0.6em;
  font-weight: light;
}

thead {
  background: #3AAA35;
}


thead div {
  color: #fff;

}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.lineField td:nth-child(5) {
  max-width: 250px !important;
  white-space: nowrap;
  overflow-x: scroll !important;
  overflow-y: auto !important;
}

.lineField td:nth-child(2) {
  max-width: 200px !important;
  white-space: nowrap;
  overflow: auto;
}


/* td{
  max-height: 100px !important;
  overflow-y:scroll !important ;
} */


/* td {
  max-width: 200px !important;
  white-space: nowrap; 
  overflow: auto;
} */
.bg-color-custom {
  background-color: #3AAA35 !important;
  color: white;

}

.seat-bg {
  background-color: #5bcd3e;
}

.day {
  background-color: rgb(231, 225, 225);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  /* Ajustez la taille selon vos besoins */
  height: 40px;
  border-radius: 50%;
  font-size: 14px;
}

.day:hover {

  color: #fff;
  background-color: rgb(104, 87, 131) !important;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: 8%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.pac-container {
  z-index: 10000 !important;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

.input input:focus {
  border: 3px solid #3aaa35;
}

.inputCol {
  position: relative;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

.nav-tabs .nav-link {
  background: #dc143c;
  transition: all .5s;
}

.nav-tabs .nav-link a {
  color: #fff;
}

.text-red {
  color: #dc143c;
}

.nav-tabs .nav-link a {
  color: #fff !important;
}

.text-green {
  color: #3AAA35;
}

.nav-tabs .nav-link a {
  color: rgba(44, 56, 74, 0.95);
}

.nav-tabs-custom .nav-item .nav-link.active a {
  color: blue;
  font-weight: 600 !important;
  vertical-align: middle;
}

.nav-tabs-custom .nav-item .nav-link.active {
  font-weight: 600;
  background: #3AAA35;
  border-bottom: 1px solid blue;
}

.nav-tabs-custom .nav-item .nav-link::after {
  border-bottom: 1px solid #000000;
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 25px;
  width: 25px;
  margin: auto;
  background-color: #d5d5d5;

  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
    10px 10px 15px rgba(70, 70, 70, 0.12);
}

input[type="checkbox"]:checked {
  background-color: #5bcd3e;
}

input[type="checkbox"]:checked:after {
  display: block;

}

input[type="checkbox"]:after {
  font-weight: 600;
  font-family: FontAwesome;
  content: "\f00c";
  width: inherit;
  height: inherit;

  font-size: 20px;
  color: white;
  display: none;
}


.seat-container {
  border: 2px solid black;
  width: 35px;
  height: 35px;
  justify-content: center;
  border-radius: 5px;
  margin-right: 5px;
  /* Add margin between seats for spacing */
}

.row.d-flex .d-inline {
  display: flex;
  flex-direction: row;
}
</style>
